import * as React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';

// App Components
import Home from './components/Home';
import GroceriesStore from './components/GroceriesStore';
import CoffeeShop from './components/CoffeeShop';
import Village from './components/Village';
import Contacts from './components/Contacts';

function app() {
  return (
    <div className="App">
      <BrowserRouter>
        <header className="App-header">
          <Navbar bg="light" expand="lg">
            <Navbar.Brand href="/">
              {'Cantinho da Despensa, Mini-Mercado & Cafe ltd.'}
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav>
                <Nav.Link href="/mini-mercado">Mini-Mercado</Nav.Link>
                <Nav.Link href="/cafe">Cafe</Nav.Link>
                <Nav.Link href="/aldeia">Aldeia de Sao Miguel</Nav.Link>
                <Nav.Link href="/contactos">Contactos</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </header>
        <div className="container">
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/mini-mercado" component={GroceriesStore} />
            <Route path="/cafe" component={CoffeeShop} />
            <Route path="/aldeia" component={Village} />
            <Route path="/contactos" component={Contacts} />
          </Switch>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default app;
