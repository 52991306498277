import * as React from 'react';
import { Container, Image } from 'react-bootstrap';

const home = () => (
  <Container>
    <section>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <h3> Em tempo de crise BAIXAMOS ainda mais os preços! </h3>
      </div>
    </section>
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Image
        src="/cantinho.jpeg"
        fluid
        alt="Vista Principal do Cantinho Da Despensa"
      />
    </div>
  </Container>
);

export default home;
